import React from 'react'
import SVG from 'react-inlinesvg'
import cn from 'classnames'

import iconTicket from '@assets/digitalzone/icon-ticket.svg'

import './index.css'

const ConferenceTicket = ({
  disabled = false,
  title,
  price,
  soldOutText,
  buyButtonText,
  buyButtonUrl,
  children
}) => {
  return (
    <div
      className={cn('conference-ticket border-box u-shadow p-40', {
        'conference-ticket--disabled': disabled
      })}
    >
      {/* status */}
      <div className="d-flex align-items-center">
        <SVG src={iconTicket} />
        {disabled && <p className="ml-auto fw-text-bold">{soldOutText}</p>}
      </div>

      {/* body */}
      <p className="h5 mt-40">{title}</p>
      <p className="h1">{price}</p>
      <p className="mt-24">{children}</p>

      {/* cta */}
      <a
        href={buyButtonUrl}
        className="cta btn bg-digitalzone c-white btn--round d-flex mt-40"
        rel="noopener noreferrer"
        target="_blank"
        tabIndex={disabled ? '-1' : '0'}
      >
        {buyButtonText}
      </a>
    </div>
  )
}

export default ConferenceTicket
