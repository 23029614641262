import React from 'react'
import cn from 'classnames'

import PageHeader from '@c/page-header'
import ConferenceTicket from '@c/conference-ticket'

const ConferencePrice = ({ title, tickets }) => {
  return (
    <section id="register" className="py-120 py-lg-160">
      <div className="container">
        {/* HEADER */}
        <div className="ta-center">
          <PageHeader type="h2" title={title} />
        </div>

        {/* PRICE */}
        <div className="row justify-content-center mt-60">
          {tickets.map((ticket, i) => (
            <div
              key={ticket.id}
              className={cn('col-12 col-lg-4', i % 2 === 1 && 'mt-32 mt-lg-0')}
            >
              <ConferenceTicket
                title={ticket.title}
                price={ticket.price}
                disabled={ticket.soldOut}
                soldOutText={ticket.soldOutText}
                buyButtonText={ticket.buyButtonText}
                buyButtonUrl={ticket.buyButtonUrl}
              >
                {ticket.description}
              </ConferenceTicket>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ConferencePrice
