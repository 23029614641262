// extracted by mini-css-extract-plugin
export var body = "index-module--body--fcc21";
export var card = "index-module--card--99512";
export var company = "index-module--company--78285";
export var photo = "index-module--photo--c6569";
export var photoFigure = "index-module--photoFigure--3afa0";
export var photoImg = "index-module--photoImg--af170";
export var subject = "index-module--subject--1f519";
export var time = "index-module--time--40f2a";
export var timeText = "index-module--timeText--81ee0";
export var timeout = "index-module--timeout--99c45";
export var timeoutText = "index-module--timeoutText--3b951";