import React from 'react'
import './index.css'
import ProjectImage from '../project-image'
function SpeakerPopup({ speaker, onClose }) {
  return (
    <div className={`speaker-popup ${speaker ? '' : 'd-none'}`}>
      <div className={'speaker-popup-content '}>
        <span className="close" onClick={() => onClose()}></span>
        <div className="portrait">
          <figure className="portrait-photo">
            {speaker?.image && <ProjectImage image={speaker.image} />}
          </figure>
          <div className="info">
            <h4>{speaker?.name}</h4>
            <p>{speaker?.title}</p>
          </div>
        </div>
        <div
          className="bio"
          dangerouslySetInnerHTML={{ __html: speaker?.biography }}
        ></div>
      </div>
    </div>
  )
}

export default SpeakerPopup
