import React, { useState } from 'react'
import Icon from '@c/icon'
import { Link } from 'gatsby'
import makeSlug from '@/utils/make-slug'
import ProjectImage from '@/components/project-image'
const ConferenceHeader = ({
  locale,
  logo,
  numberVector,
  overlayVector,
  scheduleLinkText,
  speakersLinkText,
  sponsorsLinkText,
  ticketsLinkText,
  pageName
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  return (
    <>
      {/* red-block */}
      <div
        className="dz-red-block"
        style={{
          background: `var(--c-digitalzone) url('${numberVector?.asset?.localImage?.publicURL}') no-repeat left 10px bottom 70px`,
          backgroundSize: '25vw auto'
        }}
      >
        {pageName === 'digitalzone' ? (
          <div
            style={{
              width: '700px'
            }}
          >
            <ProjectImage
              image={overlayVector}
              alt="site menu figure"
              width="inherit"
            />
          </div>
        ) : (
          <ProjectImage image={overlayVector} alt="site menu figure" />
        )}
      </div>

      {/* header */}
      <header id="digitalzone-header" className="ta-center ta-lg-left pt-40">
        <div className="container">
          {/* logo */}
          <div className="row justify-content-between align-items-center">
            <div className="col-auto">
              <div className="logo">
                <Link to={makeSlug.generic(locale)}>
                  <ProjectImage
                    width={200}
                    style={{ width: 200, objectFit: 'contain' }}
                    objectFit={'contain'}
                    image={logo}
                  />
                </Link>
              </div>
            </div>
            <div className="col-auto d-lg-none">
              <Icon
                className="digitalzone-header-ctoa"
                name={isMenuOpen ? 'close' : 'hamburger'}
                width={19}
                height={19}
                onClick={() => setMenuOpen(!isMenuOpen)}
              />
            </div>
          </div>

          {/* nav */}
          <nav className="d-none fw-text-bold d-lg-block mt-40">
            <a href="#schedule" className="link-hover-digitalzone">
              {scheduleLinkText}
            </a>
            <a href="#speaker" className="link-hover-digitalzone ml-24">
              {speakersLinkText}
            </a>
            <a href="#sponsor" className="link-hover-digitalzone ml-24">
              {sponsorsLinkText}
            </a>
            <a href="#register" className="link-hover-digitalzone ml-24">
              {ticketsLinkText}
            </a>
          </nav>
        </div>
      </header>
      <nav
        className={`container digitalzone-header-mm d-lg-none mt-16 ${
          isMenuOpen ? 'is-open' : ''
        }`}
      >
        <div className="row">
          <div className="col-12">
            <a href="#schedule" className="link-hover-digitalzone">
              {scheduleLinkText}
            </a>
          </div>
          <div className="col-12">
            <a href="#speaker" className="link-hover-digitalzone">
              {speakersLinkText}
            </a>
          </div>
          <div className="col-12">
            <a href="#sponsor" className="link-hover-digitalzone">
              {sponsorsLinkText}
            </a>
          </div>
          <div className="col-12">
            <a href="#register" className="link-hover-digitalzone">
              {ticketsLinkText}
            </a>
          </div>
        </div>
      </nav>
    </>
  )
}

export default ConferenceHeader
