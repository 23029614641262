import React from 'react'
import cn from 'classnames'

import PageHeader from '@c/page-header'
import SponsorCard from '@c/sponsor-card'

const ConferenceSponsors = ({ sponsorLists }) => {
  return (
    <section id="sponsor" className="pt-40 pt-lg-80">
      <div className="container">
        {sponsorLists.map((list, i) => (
          <div key={list.id} className={cn(i > 0 && 'mt-80')}>
            <div className="ta-center">
              <PageHeader type="h2" title={list.title} />
            </div>

            <div className="row justify-content-center mt-40">
              {list.sponsors.map(sponsor => (
                <div key={sponsor.id} className="col-6 col-lg-3 mb-32">
                  <SponsorCard image={sponsor} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default ConferenceSponsors
