import React from 'react'

const ConferenceHero = ({
  title,
  dateAndAddress,
  districtAndCity,
  registerButtonText
}) => {
  return (
    <section
      id="hero"
      className="ta-center ta-lg-left pt-80 pt-lg-100 pb-lg-200"
    >
      <div className="container">
        <div className="row justify-content-center justify-content-lg-start">
          <div className="col-12 col-lg-6">
            <h1>{title}</h1>

            <h4 className="mt-40 mx-40 mx-lg-0">
              {dateAndAddress}{' '}
              <span className="u-nowrap">{districtAndCity}</span>
            </h4>
            <div className="mt-40">
              <a
                href="https://www.airmeet.com/e/efbdf2e0-6f73-11ef-a2d5-478ed46623b6"
                className="btn bg-digitalzone c-white btn--round"
              >
                {registerButtonText}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ConferenceHero
