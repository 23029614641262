import React from 'react'
import cn from 'classnames'

import PageHeader from '@c/page-header'
import DigitalzonePlayBox from '@c/digitalzone-play-box'
import Slider from '@c/slider-flickity'
import ReactPlayer from 'react-player/youtube'
import ProjectImage from '@/components/project-image'

const ConferenceExperience = ({ title, videos }) => {
  const [playing, playingSet] = React.useState(null)

  return (
    <section id="experience" className="pt-100 pt-lg-120">
      <div className="container">
        {/* HEADER */}
        <div className="ta-center">
          <PageHeader type="h2" title={title} />
        </div>

        <Slider
          className="dz-asd mt-60 mx-n32 mx-lg-0"
          showOutside
          options={{
            pageDots: true,
            draggable: true
          }}
        >
          {videos.map((video, index) => {
            return (
              <div
                key={index}
                className={cn('slider-item px-32 px-lg-0', {
                  'show-video': index === playing
                })}
              >
                <div className="row">
                  <div className="col-12 col-lg-10 offset-lg-1">
                    {/* photo */}
                    <ProjectImage image={video.backgroundImage} />
                    {/* video */}
                    <div className="bg-player">
                      <ReactPlayer
                        playing={index === playing}
                        pip
                        width="100%"
                        height="100%"
                        controls
                        url={video.video.url}
                        onEnded={() => playingSet(null)}
                        onPause={() => playingSet(null)}
                      />
                    </div>
                    {/* play */}
                    <DigitalzonePlayBox
                      time={video.video.duration}
                      onClick={() => {
                        playingSet(index)
                      }}
                    >
                      {video.title}
                    </DigitalzonePlayBox>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}

export default ConferenceExperience
