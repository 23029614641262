import React from 'react'
import { graphql } from 'gatsby'
import isEmpty from 'lodash/isEmpty'

import SEO from '@c/seo'

import ConferenceHeader from '@v/conference/header'
import ConferenceHero from '@v/conference/hero'
import ConferenceSpeaker from '@v/conference/speaker'
import ConferenceWhat from '@v/conference/what'
import ConferencePastVideo from '@v/conference/past-video'
import ConferenceSchedule from '@v/conference/schedule'
import ConferenceSponsors from '@v/conference/sponsor'
import ConferenceExperience from '@v/conference/experience'
import ConferencePrice from '@v/conference/price'

import DatoCMSModel from '@m/DatoCMSModel'
import DatoCmsLink from '@m/datocms-components/DatoCmsLink'

import './index.css'
import SiteFooter from '@/components/site-footer'

const Page = ({ data }) => {
  const { page } = data
  const {
    header: [header = {}],
    hero: [hero = {}],
    speakers,
    sliderItems,
    pastVideo: [pastVideo = {}],
    schedule,
    sponsorLists,
    experienceVideos,
    tickets,
    numberVector,
    overlayVector,
    hideSchedule,
    locale
  } = page
  const model = new DatoCMSModel(page)
  const seo = { ...model.getSeoComponentProps() }
  seo.link = seo.link.filter((o) => o.hreflang === 'en' || !o.hreflang)
  return (
    <div className="digitalzone">
      <SEO {...seo} locale={locale} />

      {header && (
        <ConferenceHeader
          {...header}
          numberVector={numberVector}
          overlayVector={overlayVector}
          locale={locale}
          pageName="digitalzone"
        />
      )}
      {hero && <ConferenceHero {...hero} />}
      {speakers && (
        <ConferenceSpeaker title={page.speakersTitle} speakers={speakers} />
      )}
      {schedule && !hideSchedule && (
        <ConferenceSchedule
          title={page.scheduleTitle}
          schedule={schedule}
          locale={locale}
        />
      )}
      {sliderItems && (
        <ConferenceWhat
          title={page.sliderTitle}
          sliderItems={sliderItems}
          link={
            page.conferenceWhatRegisterLink[0] &&
            new DatoCmsLink(page.conferenceWhatRegisterLink[0])
          }
        />
      )}
      {!isEmpty(pastVideo) && <ConferencePastVideo {...pastVideo} />}
      {sponsorLists && <ConferenceSponsors sponsorLists={sponsorLists} />}
      {experienceVideos && (
        <ConferenceExperience
          title={page.experienceTitle}
          videos={experienceVideos}
        />
      )}
      {tickets && (
        <ConferencePrice
          costs={page.ticketPriceCosts}
          costsTitle={page.ticketPriceCostsTitle}
          title={page.ticketsTitle}
          tickets={tickets}
        />
      )}

      <SiteFooter locale={page.locale} hideNewsletter={true} />
    </div>
  )
}

export default Page

export const query = graphql`
  query DigitalzonePageQuery($locale: String) {
    page: datoCmsDigitalzonePage(locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale
      title
      hideSchedule
      sponsorFormTitle
      sponsorFormSubmitButton
      sponsorFormSuccessMessage
      sponsorFormFailMessage

      numberVector {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      overlayVector {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      header {
        logo {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
        scheduleLinkText
        speakersLinkText
        sponsorsLinkText
        ticketsLinkText
      }

      hero {
        title
        dateAndAddress
        districtAndCity
        registerButtonText
      }

      speakersTitle
      speakers {
        id
        name
        title
        talkHour
        biography
        image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }

      # Conference What section
      sliderTitle
      sliderItems {
        title
        summary
        image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
      conferenceWhatRegisterLink {
        title
        url
        page {
          ...DatoCmsLinkFieldPage
        }
      }

      pastVideo {
        title
        video {
          duration
          url
        }
      }

      scheduleTitle
      schedule {
        id
        name
        company
        photo {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }

        subject
        time
        timeout
        timeoutText
        eventDay
      }

      sponsorLists {
        id
        title
        sponsors {
          id: originalId
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }

      experienceTitle
      experienceVideos {
        title
        backgroundImage {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
        video {
          duration
          url
        }
      }

      ticketsTitle
      tickets {
        id
        title
        description
        price
        soldOut
        soldOutText
        buyButtonText
        buyButtonUrl
      }
      ticketPriceCostsTitle
      ticketPriceCosts {
        id
        percent
        icon {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }

      footer {
        bootstrapUrlKey
        contactEmailAddress
        eMailAddressLabel
        latitude
        longitude
        pinHeight
        phoneNumber
        contactUsText
        copyrightTitleSmall
        copyrightTitleBig
        submitButtonText
        submitSuccessMessage
        newsletterSubmitUrl
      }
    }
  }
`
