import React from 'react'
import cn from 'classnames'
import SVG from 'react-inlinesvg'

import * as styles from './index.module.css'
import iconPlay from '@assets/digitalzone/icon-play.svg'

function DigitalzonePlayBox({ className, time, children, onClick = () => {} }) {
  return (
    <div className={cn(styles.box, 'play-box', className)}>
      <div className={styles.header}>
        <p className={styles.headerTime}>{time}</p>
        <button className={styles.headerCta} type="button" onClick={onClick}>
          <SVG src={iconPlay} />
        </button>
      </div>
      <div className={styles.body}>
        <h2 className={styles.bodyTitle}>{children}</h2>
      </div>
    </div>
  )
}

export default DigitalzonePlayBox
