import React from 'react'
import cn from 'classnames'

import './../categories-button/index.css'
import Icon from '../icon'

function DropdownButton({
  elements,
  selected,
  active,
  onClick,
  titleKey = 'title'
}) {
  return (
    <div className="dropdown dropdown-xl py-16">
      <button className="drop-button d-flex align-items-center justify-content-around">
        {selected && selected[titleKey]}
        <Icon name="bottom" className="arrow-btn"/>
        <div className={cn('oval', active && 'oval-active')}></div>
      </button>
      <div className="dropdown-content">
        {elements.map((element, key) => {
          return (
            <button key={key} onClick={() => onClick && onClick(element)}>
              {element[titleKey]}

            </button>
          )
        })}
      </div>
    </div>
  )
}

export default DropdownButton
