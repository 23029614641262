import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './index.module.css'

import BorderBox from '../border-box'
import ProjectImage from '../project-image'

function SponsorCard({ image }) {
  return (
    <BorderBox className={styles.card}>
      <ProjectImage image={image} />
    </BorderBox>
  )
}

SponsorCard.propTypes = {
  image: PropTypes.string.isRequired
}

export default SponsorCard
