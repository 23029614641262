import React from 'react'
import PropTypes from 'prop-types'

import './index.css'
import ProjectImage from '../project-image'

function SpeakerCard({
  colorClass = '',
  photo,
  name,
  title,
  speakHour,
  onClick = () => {}
}) {
  return (
    <div
      className={`speaker-card border-all ta-center ${colorClass}`}
      onClick={onClick}
    >
      <figure className="speaker-card-photo bg-soft-blue">
        {photo && <ProjectImage image={photo} />}
      </figure>
      <div className={`speaker-card-body border-t p-24 ${colorClass}-body`}>
        <h4>{name}</h4>
        <p>{title}</p>
        <h5 className="mt-16">{speakHour}</h5>
      </div>
    </div>
  )
}

SpeakerCard.propTypes = { photo: PropTypes.object }

export default SpeakerCard
