import React from 'react'
import cn from 'classnames'
import Slider from '@c/slider-flickity'
import BorderBox from '@c/border-box'
import { FlexibleLink } from '@c/common/FlexibleLink'
import setSliderOptions from '@u/set-slider-options'
import ProjectImage from '@/components/project-image'

const ConferenceWhat = ({
  title,
  sliderItems,
  sliderOptions = setSliderOptions(),
  link
}) => {
  let percent = 60
  let shouldHide = false
  shouldHide = typeof window !== 'undefined' && window.innerWidth <= 992
  sliderOptions.groupCells = 1

  return (
    <section id="what" className="bg-digitalzone c-white py-40 py-lg-40">
      <div className="d-flex flex-column flex-lg-row align-items-center">
        <div
          className={cn(
            'd-flex flex-column align-items-center align-items-lg-start mx-0 mx-lg-120',
            { 'bg-text-what': sliderOptions.desktopRes }
          )}
        >
          <h1 className="ta-center ta-lg-left  mb-32">{title}</h1>
          {link && (
            <FlexibleLink
              className="register-btn fs-normal"
              title={link.getTitle()}
              url={link.getUrl()}
              useButton={true}
            />
          )}
        </div>
        <Slider
          className={cn('mt-40 col-12 col-lg-9', {
            'blur-effect': sliderOptions.desktopRes
          })}
          options={{ ...sliderOptions }}
        >
          {sliderItems.map((item, i) => (
            <div
              key={i}
              className="slider-item col-12 col-lg-4 px-32 px-lg-0 py-16 c-white"
            >
              <BorderBox
                className={cn('bg-transparent', {
                  'slider-box': sliderOptions.desktopRes
                })}
              >
                <figure className="ta-center">
                  <ProjectImage height={232} image={item.image} />
                </figure>
                <div className="d-flex align-items-center mt-32">
                  <h5 className="flex-grow-1 fs-x-large">{item.title}</h5>
                </div>
                <p className="fs-small mt-16">{item.summary}</p>
              </BorderBox>
            </div>
          ))}
          {[0, 1].map(
            (item, i) =>
              !shouldHide && (
                <div
                  key={i}
                  className="slider-item col-12 col-lg-4 px-32 px-lg-0 py-16 c-white nop hidden-xs"
                >
                  <BorderBox
                    className={cn('bg-transparent', {
                      'slider-box': sliderOptions.desktopRes
                    })}
                  >
                    <div className="d-flex align-items-center mt-32">
                      <h5 className="flex-grow-1 fs-x-large"></h5>
                    </div>
                    <p className="fs-small mt-16"></p>
                  </BorderBox>
                </div>
              )
          )}
        </Slider>
      </div>
      <div className={cn('d-none', { 'd-block': sliderOptions.desktopRes })}>
        <div className="ProgressBar" style={{ '--percent': percent }}></div>
      </div>
    </section>
  )
}

export default ConferenceWhat
