import React, { useState } from 'react'

import PageHeader from '@c/page-header'
import SpeakerCard from '@c/speaker-card'
import SpeakerPopup from '@c/speaker-popup'

const ConferenceSpeaker = ({ title, speakers }) => {
  const [speaker, setSpeaker] = useState(null)
  return (
    <section id="speaker" className="ta-center ta-lg-left pt-120">
      <SpeakerPopup />
      <div className="container">
        <PageHeader type="h2" title={title} />
        <SpeakerPopup speaker={speaker} onClose={() => setSpeaker(null)} />
        <div className="row mt-40 align-items-stretch justify-content-center">
          {speakers.map(speaker => (
            <div key={speaker.id} className="col-8 col-md-6 col-lg-3 mb-32">
              <SpeakerCard
                onClick={() => setSpeaker(speaker)}
                colorClass={'digitalzone-speaker'}
                name={speaker.name}
                title={speaker.title}
                speakHour={speaker.talkHour}
                photo={speaker.image}
                photoAlt={speaker.image?.alt}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ConferenceSpeaker
