import React from 'react'
import cn from 'classnames'
import ReactPlayer from 'react-player/youtube'

import DigitalzonePlayBox from '@c/digitalzone-play-box'

const ConferencePastVideo = ({ title, video }) => {
  const [playing, playingSet] = React.useState(false)

  return (
    <section
      id="past-video"
      className={cn('dz-past-video', {
        'show-video': playing
      })}
    >
      <div className="bg-player">
        <ReactPlayer
          playing={playing}
          pip
          width="100%"
          height="100%"
          controls
          url={video.url}
          onPause={() => playingSet(false)}
        />
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4 offset-lg-0">
            <DigitalzonePlayBox
              time={video.duration}
              onClick={() => playingSet(true)}
            >
              {title}
            </DigitalzonePlayBox>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ConferencePastVideo
