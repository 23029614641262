import React from 'react'
import cn from 'classnames'

import * as styles from './index.module.css'

function BorderBox({ children, className, hover = false, ...props }) {
  return (
    <div
      className={cn(styles.box, hover && styles.boxHover, className)}
      {...props}
    >
      {children}
    </div>
  )
}

export default BorderBox
