import React, { useState } from 'react'
import PageHeader from '@c/page-header'
import DigitalzoneScheduleCard from '@c/digitalzone-schedule-card'
import DropdownButton from '@c/dropdown-button'
import { DateTime } from 'luxon'

const ConferenceSchedule = ({ title, schedule, locale }) => {
  const mappedSchedules = schedule.reduce((map, card) => {
    if (!map[card.eventDay]) {
      map[card.eventDay] = []
    }
    map[card.eventDay].push(card)
    return map
  }, {})
  const listedSchedules = Object.keys(mappedSchedules).map(date => {
    return {
      eventDay: DateTime.fromISO(date)
        .setLocale(locale)
        .toLocaleString(),
      cards: mappedSchedules[date]
    }
  })
  const [selected, select] = useState(listedSchedules[0])

  return (
    <section id="schedule" className="pt-40 pt-lg-80 mb-120">
      <div className="container">
        {/* HEADER */}
        <div className="ta-center">
          <PageHeader type="h2" title={title} />
        </div>
        <div className="row justify-content-center mt-24">
          <div className="col col-auto">
            <DropdownButton
              selected={selected}
              elements={listedSchedules}
              titleKey={'eventDay'}
              onClick={s => select(s)}
            ></DropdownButton>
          </div>
        </div>

        <div className="row mt-12">
          <div className="col-12 col-lg-8 offset-lg-2">
            {selected?.cards?.map(item => (
              <DigitalzoneScheduleCard
                key={item.id}
                time={item.time}
                photo={item.photo}
                speaker={item.name}
                company={item.company}
                subject={item.subject}
                timeout={item.timeout}
                timeoutText={item.timeoutText}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ConferenceSchedule
