import React from 'react'
import cn from 'classnames'

import * as styles from './index.module.css'
import ProjectImage from '../project-image'

function DigitalzoneScheduleCard({
  time,
  photo,
  speaker,
  company,
  subject,
  timeout = false,
  timeoutText
}) {
  return (
    <div className={cn(styles.card, timeout && styles.timeout)}>
      <div className={styles.time}>
        <time className={styles.timeText}>{time}</time>
      </div>
      <div className={styles.photo}>
        <figure className={styles.photoFigure}>
          <ProjectImage className={styles.photoImg} image={photo} />
        </figure>
      </div>
      <div className={styles.body}>
        {timeout ? (
          <p className={styles.timeoutText}>{timeoutText}</p>
        ) : (
          <>
            <h4>{speaker}</h4>
            <p className={styles.company}>{company}</p>
            <p className={styles.subject}>{subject}</p>
          </>
        )}
      </div>
    </div>
  )
}

export default DigitalzoneScheduleCard
